import * as React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import VodEntry from "./vodentry";

import { Tab } from "@headlessui/react";

const VodList = ({ data, tabIndex }) => {
  return (

      <div className="mb-20">
        <Tab.Group defaultIndex={tabIndex}>
          <Tab.List className="btn-group">
            <Tab className={({ selected }) => selected ? "btn btn-sm border-white text-black bg-white hover:bg-white" : "btn btn-sm btn-outline text-white" }>
              Auburn
            </Tab>
            <Tab className={({ selected }) => selected ? "btn btn-sm border-white text-black bg-white hover:bg-white" : "btn btn-sm btn-outline text-white" }>
              El Segundo
            </Tab>
            <Tab className={({ selected }) => selected ? "btn btn-sm border-white text-black bg-white hover:bg-white" : "btn btn-sm btn-outline text-white" }>
              Stafford
            </Tab>
            <Tab className={({ selected }) => selected ? "btn btn-sm border-white text-black bg-white hover:bg-white" : "btn btn-sm btn-outline text-white" }>
              Teams
            </Tab>
            <Tab className={({ selected }) => selected ? "btn btn-sm border-white text-black bg-white hover:bg-white" : "btn btn-sm btn-outline text-white" }>
              Awards
            </Tab>
            <Tab className={({ selected }) => selected ? "btn btn-sm border-white text-black bg-white hover:bg-white" : "btn btn-sm btn-outline text-white" }>
              Day One
            </Tab>
            <Tab className={({ selected }) => selected ? "btn btn-sm border-white text-black bg-white hover:bg-white" : "btn btn-sm btn-outline text-white" }>
              Day Two
            </Tab>
            <Tab className={({ selected }) => selected ? "btn btn-sm border-white text-black bg-white hover:bg-white" : "btn btn-sm btn-outline text-white" }>
              Day Three
            </Tab>
            
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel className="">
              {data.teamOne.nodes.map((node) => (
                <VodEntry key={node.slug} data={node} />
              ))}
            </Tab.Panel>
            <Tab.Panel>
              {data.teamTwo.nodes.map((node) => (
                <VodEntry key={node.slug} data={node} />
              ))}
            </Tab.Panel>
            <Tab.Panel>
              {data.teamThree.nodes.map((node) => (
                <VodEntry key={node.slug} data={node} />
              ))}
            </Tab.Panel>
            <Tab.Panel>
              {data.teams.nodes.map((node) => (
                <VodEntry key={node.slug} data={node} />
              ))}
            </Tab.Panel>
            <Tab.Panel>
              {data.awards.nodes.map((node) => (
                <VodEntry key={node.slug} data={node} />
              ))}
            </Tab.Panel>
            <Tab.Panel>
              {data.dayOne.nodes.map((node) => (
                <VodEntry key={node.slug} data={node} />
              ))}
            </Tab.Panel>
            <Tab.Panel>
              {data.dayTwo.nodes.map((node) => (
                <VodEntry key={node.slug} data={node} />
              ))}
            </Tab.Panel>
            <Tab.Panel>
              {data.dayThree.nodes.map((node) => (
                <VodEntry key={node.slug} data={node} />
              ))}
            </Tab.Panel>
            
          </Tab.Panels>
        </Tab.Group>
      </div>

  );
};

export default VodList;
